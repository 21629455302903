import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Typography from '@mui/material/Typography';
import { HeaderBar, MessageBox, PrintPreview } from '../../components';

import {getAPI, postAPI, deleteAPI, useTabs, activityLog} from '../../utils';
import {checkUserRoles} from '../../utils/checkUserRoles';
import PostSaleShow from './PostSaleShow';
import PostSaleEdit from './PostSaleEdit';
import PostSalePrint from './PostSalePrint';
import moment from 'moment'
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';


const defaultTheme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    red: {
      main: '#f20b56ed',
      contrastText: '#fff',
    },
    green: {
      main: '#8bc34a',
      contrastText: '#fff',
    },
    yellow: {
      main: '#ffeb3b',
      contrastText: '#333',
    }
  },
});

const useStyles = makeStyles(
  (theme) => ({
    root: {
        color: '#333',
        backgroundColor: '#d7f1ff',
        padding: '10px',
    },
    textoOverflow: {        
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        maxWidth: '87%', 
        display: 'inline-block'
    }
}), { defaultTheme } )

const StatusChange = ({status, ...other}) => {
  const aprobadoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>APROBADO</Button>
  const desaprobadoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>DESAPROBADO</Button>
  const pedidoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>PEDIDO</Button>
  const confirmadoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>CONFIRMADO</Button>
  const paraPedirBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>PARA PEDIR</Button>
  
  if (status === 'PARA PEDIR') {
    return [aprobadoBtn, desaprobadoBtn]
  }
  if (status === 'APROBADO') {
    return [pedidoBtn, paraPedirBtn]
  }
  if (status === 'DESAPROBADO') {
    return [paraPedirBtn]
  }
  if (status === 'PEDIDO') {
    return [confirmadoBtn, paraPedirBtn]
  }
  if (status === 'CONFIRMADO') {
    return [ paraPedirBtn ]
  }
  return ""
}


const PostSaleShowEdit = ({docId, onReload}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [doc, setDoc ] = useState("");
    const [dialog, setDialog] = useState(false);

    // eslint-disable-next-line
    const {updateCurrentTab, removeCurrentTab} = useTabs();
    const currentUser = useSelector(state => state.currentUser);
    const disabled = !checkUserRoles(currentUser, ['local-admin','gerencia','posventa-edit'])
    const isGerencia = !checkUserRoles(currentUser, ['local-admin','gerencia'])
    
    let title = !doc || docId === 'new' ? "Nuevo PostVenta" : (edit ? "Edición de PV "  : "PV " );
    if (doc && doc._id && doc.pv) {
      title += " " + doc.pv + " " + doc.nombre + " (" + doc.status + ")";
    }

    // eslint-disable-next-line
    let classes = useStyles();

    useEffect( () => {
      debugger
      setReload(false);
      // reads data to Edit instead of using datagrid row
      if ( docId === 'new' ) return 
      getAPI('/postsale/postsales/' + docId)
      .then(data => {
        if (data) setDoc(data)
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [docId, edit, reload, enqueueSnackbar])

    const handleEdit = () => {
      setEdit(!edit)
    }

    const handleCancel = () => {
      setEdit(false)
      if (docId === 'new') {
        removeCurrentTab()
      }
    }

    const handleAfter = (doc) => {
      setEdit(false)
      if (doc) setDoc(doc)
      setReload(true)
      updateCurrentTab("PVTA")(doc)

      if (onReload) onReload()
    }

    const handleReload = () => {
      setReload(true)
    }

    // eslint-disable-next-line
    const handleChangeStatus = newStatus => ev => {
      ev.preventDefault()
      debugger
      if (newStatus === 'ENVIADA' && doc.en_garantia === 'NO' && doc.archivo_presupuesto.length == 0) {
        enqueueSnackbar("Lo siento! Debe adjuntar un presupuesto", {variant: 'error'})
        return
      }
      if (newStatus === 'CERRADA' && doc.openOS > 0) {
        enqueueSnackbar("Lo siento! Tiene OS abiertas que debe cerrar para poder continuar", {variant: 'error'})
        return
      }

      const body = {status: newStatus}
      if (newStatus === 'ENVIADA') {
        body['fecha_enviada'] = new Date()
      }
      if (newStatus === 'ACEPTADA') {
        body['fecha_aceptada'] = new Date()
      }
      if (newStatus === 'APROBADA') {
        body['fecha_aprobada'] = new Date()
      }
      if (newStatus === 'CERRADA') {
        body['fecha_cerrada'] = new Date()
      }
      postAPI('/postsale/postsales/' + docId, body)
      .then(data => {
        debugger
        const diffA = "Se modificó el estado de " + doc.status + " a " + newStatus;
        activityLog({label: doc.nombre, app:'posventa', docId, msg:diffA, currentUser})
        enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})
        setReload(true)
      })
      .catch(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
      });
    }

    const handleDialog = (tipo) => (ev) => {      
      setDialog( !!tipo )
    }
    const handleDialogClose = (action) => {
      debugger
        if (action) {
          deleteAPI('/postsale/postsales/' + docId)
          .then(data => {
            debugger
            const msg = 'Se borró el documento y sus adjuntos.'
            activityLog({label: doc.nombre, app: 'posventa', docId, msg, currentUser})
            enqueueSnackbar(msg, {variant: 'info'})
          })
          .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
          });
  //            onSubmit(fields)
        }
        setDialog(false)
    }
    
    const info = [
      <Box sx={{float: 'right'}}>
        { doc.status === 'INGRESADA' && doc.en_garantia === 'NO' && <Button color='green' onClick={ handleChangeStatus("A PRESUPUESTAR")} size='small' variant='contained'>A PRESUPUESTAR</Button>} &nbsp;
        { doc.status === 'INGRESADA' && doc.en_garantia === 'SI' && <Button color='green' onClick={ handleChangeStatus("APROBADA")} size='small' variant='contained'>APROBADA</Button>} &nbsp;
        { doc.status === 'INGRESADA' && doc.en_garantia === 'SI' && <Button color='red' onClick={ handleChangeStatus("DESAPROBADA")} size='small' variant='contained'>DESAPROBADA</Button>} &nbsp;
        { doc.status === 'A PRESUPUESTAR'   && <Button color='yellow'  onClick={ handleChangeStatus("ENVIADA")} size='small' variant='contained'>ENVIADA</Button>} &nbsp;
        { doc.status === 'ENVIADA'   && <Button color='yellow'  onClick={ handleChangeStatus("INGRESADA")} size='small' variant='contained'>INGRESADA</Button>} &nbsp;
        { doc.status === 'ENVIADA'   && <Button color='green'  onClick={ handleChangeStatus("ACEPTADA")} size='small' variant='contained'>ACEPTADA</Button>} &nbsp;
        { doc.status === 'ENVIADA'   && <Button color='red'  onClick={ handleChangeStatus("NO ACEPTADA")} size='small' variant='contained'>NO ACEPTADA</Button>} &nbsp;
        { doc.status === 'ACEPTADA'  && <Button color='green'  onClick={ handleChangeStatus("APROBADA")} size='small' variant='contained'>APROBADA</Button>} &nbsp;
        { doc.status === 'ACEPTADA'  && <Button color='red'  onClick={ handleChangeStatus("DESAPROBADA")} size='small' variant='contained'>DESAPROBADA</Button>} &nbsp;
        { doc.status === 'APROBADA'  && <Button color='green'  onClick={ handleChangeStatus("CERRADA")} size='small' variant='contained'>CERRADA</Button>} &nbsp;
        { doc.status === 'APROBADA'  && <Button color='yellow'  onClick={ handleChangeStatus("SIN COBRAR")} size='small' variant='contained'>SIN COBRAR</Button>} &nbsp;
        { doc.status === 'SIN COBRAR' && <Button color='green'  onClick={ handleChangeStatus("CERRADA")} size='small' variant='contained'>CERRADA</Button>} &nbsp;
      </Box>
    ]
  // {label: "INGRESADA", value: "INGRESADA"},
  // {label: "ACEPTADA", value: "ACEPTADA"},
  // {label: "APROBADA", value: "APROBADA"},
  // {label: "CERRADA", value: "CERRADA"},
  // {label: "NO ACEPTADA", value: "NO ACEPTADA"},
  // {label: "ENVIADA", value: "ENVIADA"},
  // {label: "SIN COBRAR", value: "SIN COBRAR"},
  // {label: "DESAPROBADA", value: "DESAPROBADA"},

    return (
        <Paper sx={{
          p: 1,
          width: '100%',
          height: '100%',
        }}> 
          <HeaderBar 
            title={[
              <Box sx={{mt: 2}}>
                {title} 

                { docId !== 'new' && 
                  <Typography sx={{ color: 'white', display: 'inline'}} variant='h6'>
                    [{doc.tipo}] &nbsp;
                    {doc.status === 'ACEPTADA' && " el " + moment(doc.fecha_aceptada).utc().format('YYYY-MM-DD') }
                    {doc.status === 'APROBADA' && " el " + moment(doc.fecha_aprobada).utc().format('YYYY-MM-DD') }
                    {doc.status === 'CERRADA' && " el " + moment(doc.fecha_cerrada).utc().format('YYYY-MM-DD') }
                  </Typography>
                } 

                <Box sx={{display: 'inline', '@media print': {display: 'none'}}}>

                      <ActivityOpenClose sx={{float: 'right', color: '#ddd'}} app='posventa' docId={docId}/>

                      {!edit && docId !== 'new' && <PrintPreview sx={{float: 'right', color: '#ddd'}} component={<PostSalePrint doc={doc}/>}/>}

                      <Button autoFocus color="inherit" sx={{float: "right", '@media print': {display: 'none'}}} disabled={disabled} onClick={handleEdit} size='small' variant="outlined">{!edit && docId !== 'new'  ? "Editar" : "Cancelar"}</Button>

                      {edit && docId !== 'new' && isGerencia && <Button autoFocus color="inherit" sx={{float: "right", mr:1, '@media print': {display: 'none'}}} disabled={disabled} onClick={handleDialog(true)} size='small' variant="outlined">Borrar</Button>}

                      {/* <Button autoFocus color="inherit" sx={{float: "right"}} disabled={disabled} onClick={handleInspect}>{edit ? "Ver" : ""}</Button> */}
                    </Box>

                    { !disabled && !edit && docId !== 'new' && info }

                </Box>

            ]}
          />

          { !edit && docId !== 'new' && 
            <PostSaleShow 
              sx={{overflow: 'hidden', height: '100%'}}
              doc={doc}
              onReload={handleReload}
            /> 
          }

          { (edit || docId === 'new') && 
            <PostSaleEdit 
                sx={{m:3, overflow: 'auto', height: "calc(100% - 200px)"}}
                edit={edit || docId === 'new'}
                docId={docId} 
                onAfterSubmit={handleAfter}
                onCancel={handleCancel}
            /> 
          }

          <MessageBox open={dialog} title='Esta seguro que quiere borrar el documento?' onClose={handleDialogClose}>
            Se van a eliminar todos los adjuntos que tenga este documento.
          </MessageBox>

        </Paper>
  )
}

export default PostSaleShowEdit;